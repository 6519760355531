<template>
  <div class="header-container" :class="{ expand: isMenuOpen }">
    <div class="header__logo">
      <div v-if="user" class="flex">
        <div
          class="hamburger-icon"
          :class="{ open: isMenuOpen }"
          @click="toggleMenu"
          v-if="user.isUserAccess"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div>
          <router-link to="/formulation-mapping" v-if="user.isUserAccess">
            <Logo />
          </router-link>
          <Logo v-else />
        </div>
      </div>
    </div>
    <div class="tittle">
      <img src="../../assets/images/pioti-logo.png" width="350px" />
    </div>
    <div class="user-container">
      <Loader
        :loading="isLoader"
        v-if="isLoader"
        :color="color"
        :size="loaderSize"
      />
      <div class="userName" v-else>
        <ProfilePicture :size="size" />
        <UserName />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Header",
  components: {
    Logo: () =>
      import(/* webpackPrefetch: true */ "../common/svg-icons/LogoIcon"),
    UserName: () => import(/* webpackPrefetch: true */ "../common/UserName"),
    ProfilePicture: () =>
      import(/* webpackPrefetch: true */ "../common/ProfilePicture"),
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
  },
  data: () => ({
    size: true,
    isLoader: true,
    color: "#fff",
    loaderSize: "8px",
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
      this.validateUserAccess();
    }, 500);
  },
  computed: {
    ...mapGetters({
      user: "GET_USER_ROLE",
      openMenu: "GET_IS_MENU_OPEN",
    }),
    isMenuOpen: {
      get() {
        return this.openMenu;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    ...mapMutations({
      isMenuOpened: "SET_IS_MENU_OPEN"
    }),
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.isMenuOpened(!this.isMenuOpen);
    },
    validateUserAccess() {
      if (!this.user.isUserAccess) {
        this.$router.push({ name: "unauthorised" }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  height: 43px;
  background-color: #042042;
  background-image: url("../../assets/images/header-bg-strip.png");
  background-repeat: no-repeat;
  background-size: 600px 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: margin-left 0.5s;
  align-items: center;
  .header__logo {
    display: flex;
  }

  .user-container {
    margin-right: 10px;
    min-width: 100px;
    .userName {
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .hamburger-icon {
    height: 25px;
    width: 20px;
    position: relative;
    margin: 12px 10px 0px 10px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 99;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #d2cece;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0;
    }
    span:nth-child(2) {
      top: 6px;
    }
    span:nth-child(3) {
      top: 12px;
    }
  }
  .open {
    span {
      background: #989898;
    }
    span:nth-child(1) {
      top: 6px;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }
    span:nth-child(3) {
      top: 6px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}
.expand {
  transition: margin-left 0.5s;
  // margin-left: 220px;
}
</style>
