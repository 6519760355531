// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/header-bg-strip.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header-container[data-v-ed558de2] {\n  height: 43px;\n  background-color: #042042;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 600px 44px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  transition: margin-left 0.5s;\n  align-items: center;\n}\n.header-container .header__logo[data-v-ed558de2] {\n  display: flex;\n}\n.header-container .user-container[data-v-ed558de2] {\n  margin-right: 10px;\n  min-width: 100px;\n}\n.header-container .user-container .userName[data-v-ed558de2] {\n  color: #fff;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n}\n.header-container .hamburger-icon[data-v-ed558de2] {\n  height: 25px;\n  width: 20px;\n  position: relative;\n  margin: 12px 10px 0px 10px;\n  transform: rotate(0deg);\n  transition: 0.5s ease-in-out;\n  cursor: pointer;\n  z-index: 99;\n}\n.header-container .hamburger-icon span[data-v-ed558de2] {\n  display: block;\n  position: absolute;\n  height: 2px;\n  width: 100%;\n  background: #d2cece;\n  border-radius: 9px;\n  opacity: 1;\n  left: 0;\n  transform: rotate(0deg);\n  transition: 0.25s ease-in-out;\n}\n.header-container .hamburger-icon span[data-v-ed558de2]:nth-child(1) {\n  top: 0;\n}\n.header-container .hamburger-icon span[data-v-ed558de2]:nth-child(2) {\n  top: 6px;\n}\n.header-container .hamburger-icon span[data-v-ed558de2]:nth-child(3) {\n  top: 12px;\n}\n.header-container .open span[data-v-ed558de2] {\n  background: #989898;\n}\n.header-container .open span[data-v-ed558de2]:nth-child(1) {\n  top: 6px;\n  transform: rotate(135deg);\n}\n.header-container .open span[data-v-ed558de2]:nth-child(2) {\n  opacity: 0;\n  left: -60px;\n}\n.header-container .open span[data-v-ed558de2]:nth-child(3) {\n  top: 6px;\n  transform: rotate(-135deg);\n}\n.expand[data-v-ed558de2] {\n  transition: margin-left 0.5s;\n}", ""]);
// Exports
module.exports = exports;
