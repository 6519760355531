var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-container", class: { expand: _vm.isMenuOpen } },
    [
      _c("div", { staticClass: "header__logo" }, [
        _vm.user
          ? _c("div", { staticClass: "flex" }, [
              _vm.user.isUserAccess
                ? _c(
                    "div",
                    {
                      staticClass: "hamburger-icon",
                      class: { open: _vm.isMenuOpen },
                      on: { click: _vm.toggleMenu }
                    },
                    [_c("span"), _c("span"), _c("span")]
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _vm.user.isUserAccess
                    ? _c(
                        "router-link",
                        { attrs: { to: "/formulation-mapping" } },
                        [_c("Logo")],
                        1
                      )
                    : _c("Logo")
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "user-container" },
        [
          _vm.isLoader
            ? _c("Loader", {
                attrs: {
                  loading: _vm.isLoader,
                  color: _vm.color,
                  size: _vm.loaderSize
                }
              })
            : _c(
                "div",
                { staticClass: "userName" },
                [
                  _c("ProfilePicture", { attrs: { size: _vm.size } }),
                  _c("UserName")
                ],
                1
              )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tittle" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/images/pioti-logo.png"),
          width: "350px"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }